<template>
  <h1 class="hidden">Laravel Nova Versions Compare</h1>
  <CompareSection />
  <section class="text-center">
    <h2 class="text-center text-lg mb-3 font-medium">
      Directly compare the changes of a file with a validated Nova license
    </h2>
    <img
      src="./../../../resources/assets/file-compare.png"
      alt="file-compare"
      class="border rounded"
    />
  </section>
  <section class="text-center">
    <h2 class="text-center text-lg mb-3 font-medium">
      Get Notification for every new Laravel Nova Release.
    </h2>
    <img
      src="./../../../resources/assets/notify-link.png"
      alt="file-compare"
      class="border rounded"
    />
  </section>
</template>
<script>
export default {}
</script>
